import React from 'react'
import { TagCloud } from 'react-tagcloud'
import convertArraysInArrayToObject from '../utils/convertArraysInArrayToObject'
import setupTags from '../utils/setupTags'
import styled from 'styled-components'

const TagCloudCustomized = ({ title, tags }) => {
  const newTagsList = setupTags(tags)
  const newTagsObject = convertArraysInArrayToObject(newTagsList)
  const options = {
    luminosity: 'bright',
    format: 'rgb(225,200,20)',
  }
  return (
    <Wrapper>
      <h3 className="tags-title">{title}</h3>
      <TagCloud
        minSize={12}
        maxSize={35}
        tags={newTagsObject}
        colorOptions={options}
      />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--max-width);
  margin: 0 auto;
  margin-top: 8rem;
  .tags-title {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--clr-black);
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 700px) {
    width: 92vw;
  }
`

export default TagCloudCustomized

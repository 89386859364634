import React from 'react'
import replaceSpaceWithDash from '../utils/replaceSpaceWithDash'
import { Link } from 'gatsby'
import styled from 'styled-components'

const EachTag = ({ tags }) => {
  return (
    <Wrapper>
      {tags &&
        tags.map(tag => {
          let tagWithHyphen = replaceSpaceWithDash(tag)
          return (
            <li key={tag}>
              <Link to={`/tag/${tagWithHyphen}/`} className="link">
                {tag}
              </Link>
            </li>
          )
        })}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  li {
    display: flex;
    padding: 0 0.075rem;
    .link {
      color: var(--clr-white);
      background: var(--clr-base-2);
      font-size: 0.65rem;
      font-weight: 500;
      text-transform: uppercase;
      border-radius: 1.5625rem;
      padding: 0.4375rem 0.875rem;
    }
    &:hover {
      transform: translateY(-2.5px);
    }
  }
`

export default EachTag

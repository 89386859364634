import React from 'react'
import { IoMdClose } from 'react-icons/io'
import { PageLinks } from '../constants'
import { Categories } from '../components'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <aside className={`sidebar ${isOpen ? 'showSidebar' : ''}`}>
      <button className="close-btn" onClick={toggle} aria-label="닫기 버튼">
        <IoMdClose />
      </button>
      <div className="sidebar-container">
        <PageLinks styleClass="sidebar-links">
          <Categories division="post" isSubcategoryClosed isTotalCount />
        </PageLinks>
      </div>
    </aside>
  )
}

export default Sidebar

const setupTags = all => {
  const allTags = {}

  all.forEach(node => {

    node.frontmatter.tags.forEach(tag => {
      if (!allTags[tag]) {
        allTags[tag] = 1
      } else {
        allTags[tag] += 1
      }
      // if (!allTags[tag]) {
      //   allTags[tag] = urlEndpoint
      // } else {
      //   allTags[tag] += `, ${urlEndpoint}`
      // }
    })
  })

  const newTags = Object.entries(allTags).sort((a, b) => {
    const [firstTag] = a
    const [secondTag] = b

    return firstTag.localeCompare(secondTag)
  })

  return newTags
}

export default setupTags

/**
 const list = [
   {covid19: [werewe, gsrwew, twet]},
   {werewr: [erwer, url1, url3]}
 ] 
 */

import React from 'react'
import replaceSpaceWithDash from '../utils/replaceSpaceWithDash'
import setupTags from '../utils/setupTags'
import { graphql, useStaticQuery, Link } from 'gatsby'

const query = graphql`
  query tagsCategory {
    allMdx(filter: { frontmatter: { published: { eq: true } } }) {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`

const TagsList = ({ className }) => {
  const {
    allMdx: { nodes: all },
  } = useStaticQuery(query)

  const newTagsList = setupTags(all)

  return (
    <>
      {newTagsList.map((tag, index) => {
        const [text, value] = tag
        let textWithHyphen = replaceSpaceWithDash(text)
        return (
          <li key={index}>
            <Link
              to={`/tag/${textWithHyphen}/`}
              key={index}
              className={className}
            >
              {text}({value})
            </Link>
          </li>
        )
      })}
    </>
  )
}

export default TagsList

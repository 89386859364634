import React from 'react'
import { Link } from 'gatsby'

const PageLinks = ({ styleClass, children }) => {
  return (
    <ul className={styleClass}>
      <li>
        <Link to="/" className="page-link">
          홈
        </Link>
      </li>
      <li>
        <Link to="/blog/posts/" className="page-link">
          글
        </Link>
        {children}
      </li>
      <li>
        <Link to="/blog/projects/" className="page-link">
          프로젝트
        </Link>
      </li>
      <li>
        <Link to="/contact/" className="page-link">
          문의
        </Link>
      </li>
      <li>
        <Link to="/tags/" className="page-link">
          태그
        </Link>
      </li>
    </ul>
  )
}

export default PageLinks

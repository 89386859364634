import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import capitalize from '../../utils/capitalize'
import { useLocation } from '@reach/router'

const SEO = ({
  article = false,
  title = null,
  subtitle = null,
  description = null,
  image = null,
  datePublished = null,
  dateLastUpdated = null,
}) => {
  const { site } = useStaticQuery(query)
  const { pathname } = useLocation()

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      siteAdditionalUrl,
      defaultTitle,
      titleTemplate,
      defaultDescription,
      defaultImage,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      ogLanguageAlternate,
      author,
      facebook,
      twitter,
    },
  } = site

  const seo = {
    title: capitalize(title) || defaultTitle,
    subtitle: capitalize(subtitle) || defaultDescription,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname || ''}`,
    additionalUrl: `${siteAdditionalUrl}${pathname || ''}`,
  }

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    sameAs: [siteAdditionalUrl],
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2022',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2022-03-01T10:00:00+00:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultImage}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: '2022',
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Person',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: datePublished,
      dateModified: dateLastUpdated,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      sameAs: seo.additionalUrl,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: siteLanguage }}
        title={seo.title}
        titleTemplate={titleTemplate}
        link={[{ rel: 'canonical', key: seo.url, href: seo.url }]}
      >
        {/* General tags */}
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Site verification */}
        <meta
          name="google-site-verification"
          content="tukSXqYli-zVIxFpk4WCWORZhgCcSgum1iUCJz5frOU"
        />
        {/* Google snippet */}
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-PJ3QHND"></script>
        {/* Google Adsense */}
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8826821401386361"
          crossorigin="anonymous"
        ></script>
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        type={article ? 'article' : 'website'}
        name={facebook}
        url={seo.url}
        locale={ogLanguage}
        localeAlternate={ogLanguageAlternate}
        title={seo.title}
        description={seo.subtitle}
        image={seo.image}
      />
      <Twitter
        username={twitter}
        title={seo.title}
        description={seo.subtitle}
        image={seo.image}
      />
    </>
  )
}

SEO.propTypes = {
  article: PropTypes.bool,
  pathname: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  datePublished: PropTypes.string,
  dateLastUpdated: PropTypes.string,
}

const query = graphql`
  query {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        siteAdditionalUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultImage: image
        defaultBanner: banner
        headline
        siteLanguage
        ogLanguage
        ogLanguageAlternate
        author
        facebook
        twitter
      }
    }
  }
`
export default SEO


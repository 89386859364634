import React from 'react'
import { Link } from 'gatsby'
import Banner from '../Banner'
import Post from './Post'
import styled from 'styled-components'

const Posts = ({ posts, division, category, subcategory }) => {
  return (
    <Wrapper>
      <h3 className="posts-title">
        {division && (
          <Link to={`/blog/${division}s/`}>
            {division === 'post' ? '글' : '프로젝트'}
          </Link>
        )}
        {category && (
            <>
              <span> » </span>
              <Link to={`/blog/category/${category}/`}>
                {category}
              </Link>
            </>
          )}
        {category &&
          subcategory && (
            <>
              <span> » </span>
              <Link
                to={`/blog/category/${category}/${subcategory}/`}
              >
                {subcategory}
              </Link>
            </>
          )}
      </h3>
      <div className="posts-center">
        {/* posts column */}
        <div className="posts-grid">
          {posts.map(post => {
            return <Post key={post.id} {...post} />
          })}
        </div>
        {/* banner column */}
        <Banner />
      </div>
    </Wrapper>
  )
}

export default Posts

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--max-width);
  margin: 8rem auto 2rem auto;
  .posts-title {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--clr-content);
    font-size: 1.375rem;
    margin-bottom: 2rem;
    a {
      cursor: pointer;
      color: var(--clr-content);
    }
    :target {
      background-color: var(--clr-hyperlink);
      color: var(--clr-hyperlink);
    }
    span {
      font-weight: 300;
    }
  }
  .posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: minmax(auto, max-content);
    column-gap: 1rem;
    row-gap: 2rem;
    margin-bottom: 6rem;
  }

  @media screen and (min-width: 700px) {
    width: 92vw;
  }
  @media screen and (min-width: 1170px) {
    .posts-center {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 1.5rem;
    }
  }
`

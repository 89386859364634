import React from 'react'
import { Link } from 'gatsby'
import Title from '../components/Banner/Title'
import styled from 'styled-components'

const TableOfContents = ({ tableOfContents, postUrl }) => {
  function renderTags(content) {
    return (
      <ul>
        {content.map(i => (
          <li key={i.url}>
            <Link to={postUrl + i.url} key={i.url} className="sub-topic">
              {i.title}
            </Link>
          </li>
        ))}
      </ul>
    )
  }
  return (
    <Wrapper>
      {/* {addsOn && <Adsense type="display-toc" />} */}
      <details open>
        <summary
          style={{
            color: 'var(--clr-primary-text-color)',
          }}
        >
        <Title title="목차" off></Title>
        </summary>
        <div className="toc-contents-ads">
          <ol style={{ marginBottom: '1rem' }}>
            {tableOfContents?.items.map(i => (
              <li key={i.url}>
                <Link to={postUrl + i.url} key={i.url} className="topic">
                  {i.title}
                </Link>
                {i.items && renderTags(i.items)}
              </li>
            ))}
          </ol>
        </div>
      </details>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, auto));
  max-height: 400px;
  details > summary {
    list-style-type: None;
  }
  .toc-contents-ads {
    margin-left: 0.5rem;
  }
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--clr-hover-border-background-dark);
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--clr-base);
  }
  ol {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: max-content;
    color: var(--clr-content-semiemphasis);
    li {
      font-size: 13px;
    }
  }
  li {
    a {
      color: var(--clr-primary-text-color);
      &:hover {
        background: var(--clr-hover-border-background);
        border-radius: var(--radius);
        padding: 0.125rem 0.375rem;
      }
    }
  }
`
export default TableOfContents

import React from 'react'
import { useLocation } from '@reach/router'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const Comment = ({ id, title }) => {
  const { pathname } = useLocation()
  const { siteUrl } = useSiteMetadata()

  let disqusConfig = {
    url: `${siteUrl}${pathname}`, // 페이지 주소
    identifier: id, // 페이지의 유니크한 값
    title, // 페이지 제목
  }

  return (
    <div
      className="comment-section"
      style={{
        minHeight: '100px',
        maxWidth: 'var(--fixed-width)',
        width: '85vw',
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      <CommentCount config={disqusConfig} placeholder={'...'} />
      <Disqus config={disqusConfig} style={{ marginBottom: '2rem' }} />
    </div>
  )
}
export default Comment

import React from 'react'
import styled from 'styled-components'

const InlineQuote = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.q`
  // font-style: italic;
  // color: var(--clr-content-semiemphasis);
  // line-height: 1.8;
  // word-spacing: 3px;
  // font-size: 1.2rem;
  // margin: 2rem 0;
  // margin-right: 2rem;
  // .icon {
  //   font-size: 6rem;
  //   color: var(--clr-base);
  //   opacity: 0.3;
  // }
  // @media (min-width: 576px) {
  //   display: grid;
  //   grid-template-columns: auto 1fr;
  //   column-gap: 2rem;
  //   align-items: center;
  // }
`

export default InlineQuote

import React from 'react'
import styled, { css } from 'styled-components'
import { Adsense } from '../../components'

const commonStyles = css`
  max-width: var(--fixed-width);
  line-height: 1.25;
  border-radius: var(--radius);
  a {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
    svg {
      visibility: hidden;
    }
  }
  &:hover {
    a {
      svg {
        visibility: visible;
      }
    }
  }
`

const headingSpecificStyles = {
  h2: css`
    ${commonStyles}
    margin: 2.5rem auto;
    color: var(--clr-content);
    .underline {
      width: 3.5rem;
      height: 5px;
      background: var(--clr-base);
      opacity: 0.9;
      margin-top: 1rem;
    }
    @media (min-width: 992px) {
      & {
        width: 92vw;
      }
    }
  `,
  h3: css`
    ${commonStyles}
    margin: 1.875rem auto;
    background: var(--clr-hyperlink-dark);
    color: var(--clr-white);
    padding: 0.75rem 1rem;
  `,
  h4: css`
    ${commonStyles}
    margin: 1.25rem auto;
    color: var(--clr-hyperlink);
  `,
  h5: css`
    ${commonStyles}
    margin: 0.875rem auto;
    color: var(--clr-content-semiemphasis);
  `,
}

const CustomHeading = styled.div`
  ${({ as }) => headingSpecificStyles[as] || ''}
`

// 고차 컴포넌트 정의
const withCustomHeading = headingLevel => props => (
  <>
    {headingLevel === 'h2' && <Adsense type="in-article" />}
    <CustomHeading as={headingLevel} {...props}>
      {props.children}
      {headingLevel === 'h2' && <div className="underline"></div>}
    </CustomHeading>
  </>
)

// 헤딩 컴포넌트들을 동적으로 생성
const [StyledH2, StyledH3, StyledH4, StyledH5] = ['h2', 'h3', 'h4', 'h5'].map(withCustomHeading)

export { StyledH2, StyledH3, StyledH4, StyledH5 }

/*
const AdsenseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  .square {
    width: 336px;
    height: 280px;
    margin-left: 0.75px;
    margin-right: 0.75px;
  }
  @media (min-width: 795px) {
    flex-direction: row;
  }
  .pc-only {
    display: none;
    @media (min-width: 795px) {
      display: inline-block;
    }
  }
`
*/
import React from 'react'
import Title from './Title'
import { TagsList } from '../../components'
import styled from 'styled-components'

const BannerTags = ({ title }) => {
  return (
    <Wrapper>
      <Title title={title} />
      <details>
        <summary>리스트 펼쳐보기</summary>
        <TagsList className="tags" />
      </details>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  list-style: none;

  details {
    margin-left: 1rem;
  }

  summary {
    cursor: pointer;
    list-style: none;
  }

  summary::before {
    content: '+';
    margin-right: 0.5rem;
  }

  details[open] summary::before {
    content: '-';
  }

  .tags {
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--clr-content-semiemphasis);
    text-transform: capitalize;
    display: block;
    padding: 0.25rem 0.6rem;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    border-radius: var(--radius);
    line-height: 1.25;
    margin-left: 0.675rem;
  }

  .tag:hover {
    background: var(--clr-hover-border-background-dark);
  }
`

export default BannerTags

import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            titleAlt
            titleTemplate
            description
            headline
            siteUrl
            siteAdditionalUrl
            siteLanguage
            ogLanguage
            ogLanguageAlternate
            image
            author
            email
            github
            facebook
            twitter
            disqus
            sitemapUrl
            rssFeedUrl
            adClient
            adSlotDisplaySidebarBottom
            adSlotDisplayInArticleLeft
            adSlotDisplayInArticleRight
            adSlotInArticle
            adSlotDisplayHome
            adSlotMultiplex
            adSlotDisplayBanner
          }
        }
      }
    `
  )
  return site.siteMetadata
}

const convertArraysInArrayToObject = all => {
  const data = all.reduce((acc, cur) => {
    const value = cur[0]
    const count = cur[1]
    const obj = { value: value, count: count }
    acc.push(obj)
    return acc
  }, [])
  return data
}

export default convertArraysInArrayToObject

import React from 'react'
import styled from 'styled-components'
import { Categories } from '../../components'
import Title from './Title'

const BannerCategories = () => {
  return (
    <>
      <Wrapper>
        <Title title="글" />
        <Categories division="post" />
      </Wrapper>
      <Wrapper>
        <Title title="프로젝트" />
        <Categories divison="project" />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.ul`
  .category-link,
  .subcategory-link {
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
    padding: 0.25rem 0 0.25rem 0.6rem;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    border-radius: var(--radius);
    color: var(--clr-content);
    display: flex;
    justify-content: space-between;
    &:hover {
      background: var(--clr-hover-border-background);
    }
    .category-counts {
      background: var(--clr-base-decoration);
      color: var(--clr-white);
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.7;
    }
  }
  .subcategory-link {
    font-size: 0.85rem;
    font-weight: 400;
    margin-right: 0.4rem;
  }
`

export default BannerCategories

import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Background = ({ showImage, title }) => {
  return (
    <Wrapper>
      {showImage && (
        <StaticImage
          src="../assets/fireplace.jpg"
          alt="fireplace"
          layout="fullWidth"
          placeholder="blurred"
          className="img"
        />
      )}
      <div className="info">
        <article>
          <h3>개발 기록</h3>
          <h1>{title}</h1>
          <Link to="/blog/projects/">프로젝트</Link>
        </article>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 100vh;
  position: relative;
  .img {
    height: 100%;
    margin-top: 5rem;
  }
  .info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: end end;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
  article {
    width: 50vw;
    height: 50vh;
    max-width: 800px;
    min-width: 200px;
    color: var(--clr-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.25;
      margin: 1rem 0 2rem 0;
      letter-spacing: 3px;
    }
    h3 {
      font-weight: 400;
      font-family: 'Poppins';
    }
    a {
      background: transparent;
      border: 2px solid var(--clr-white);
      border-radius: var(--radius);
      padding: 0.25rem 1rem;
      text-transform: capitalize;
      letter-spacing: 5px;
      color: var(--clr-white);
      font-size: 1rem;
      cursor: pointer;
      transition: var(--transition);
    }
    a:hover {
      background: rgba(255, 255, 255, 0.3);
      font-weight: 800;
    }
    @media (min-width: 800px) {
      /* padding: 0 1rem; */
      a {
        font-size: 1.25rem;
        padding: 0.5rem 1.25rem;
      }
      h1 {
        letter-spacing: 5px;
      }
    }
  }
`

export default Background

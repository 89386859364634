import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const PrevNextNavBar = edges => {
  const { previous, next } = edges

  function getNavPostSection(postInfo) {
    const {
      frontmatter: { title, slug },
    } = postInfo

    return (
      <Link to={`/blog/${slug}/`}>
        <button type="button" className="btn nav-posts-btn">
          <span className="post-title">{title}</span>
        </button>
      </Link>
    )
  }

  return (
    <Wrapper>
      <div className="prev-next-posts">
        {previous ? (
          <span className="prev-post">
            이전 글{getNavPostSection(previous)}
          </span>
        ) : (
          <span></span>
        )}
        {next ? (
          <span className="next-post">다음 글{getNavPostSection(next)}</span>
        ) : (
          <span></span>
        )}
      </div>
      <Link to="/">
        <button type="button" className="btn center-btn">
          홈으로 돌아가기
        </button>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--fixed-width);
  .prev-next-posts {
    display: flex;
    flex-direction: column;
    align-items: top;
    .nav-posts-btn {
      background-color: var(--clr-hover-border-background);
      color: var(--clr-content-emphasis);
      width: 250px;
      min-height: 50px;
      margin-top: 0.5rem;
    }
    .prev-post,
    .next-post {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      border-radius: var(--radius);
      font-weight: 700;
    }
    .next-post {
      align-items: flex-end;
    }
  }
  @media screen and (min-width: 600px) {
    .prev-next-posts {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`

export default PrevNextNavBar

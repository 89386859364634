import React from 'react'

const Sup = ({ children, display }) => {
  if (display === 'exponent') {
    return (
      <sup
        style={{
          fontSize: '0.9rem'
        }}
      >
        {children}
      </sup>
    )
  } 
  else {
    return (
      <sup
        style={{
          fontSize: '0.75rem',
          color: 'var(--clr-hyperlink)',
        }}
      >
        [{children}]
      </sup>
    )
  }
}

export default Sup

import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const Twitter = ({
  username = null,
  type = 'summary_large_image',
  title,
  description,
  image,
}) => (
  <Helmet>
    {username && <meta name="twitter:creator" content={username} />}
    <meta name="twitter:card" content={type} />
    <meta name="twitter:site" content={username} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={description} />
    <meta name="twitter:image:width" content="1200" />
    <meta name="twitter:image:height" content="675" />
  </Helmet>
)

export default Twitter

Twitter.propTypes = {
  type: PropTypes.string,
  username: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
import React from 'react'
import TagsList from './TagsList'
import styled from 'styled-components'

const TagsFromAtoZ = ({ title }) => {

  return (
    <Wrapper>
      <h3 className="tags-title">{title}</h3>
      <div className="tags-center">
        <div className="tags-grid">
          <TagsList />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--max-width);
  margin: 4rem auto;
  .tags-title {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--clr-black);
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  .tags-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  }
  li {
    list-style: none;
    a {
      display: flex;
      align-items: center;
      color: var(--clr-content);
      padding: 0.1rem 0.5rem;
      &:hover {
        background: var(--clr-hover-border-background-dark);
        border-radius: var(--radius);
      }
    }
  }
  @media screen and (min-width: 700px) {
    width: 92vw;
  }
`

export default TagsFromAtoZ

import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Title from './Banner/Title'

const Trend = ({ posts, title }) => {
  return (
    <Wrapper>
      <Title title={title} />
      {posts.map(({ id, fields, frontmatter }) => {
        const { categoryPublicNameKR } = fields
        const { title, slug, date, featuredImage } = frontmatter
        return (
          <Link to={`/blog/${slug}/`} key={id} className="banner-post">
            <GatsbyImage
              image={getImage(featuredImage)}
              alt={title}
              className="img"
            ></GatsbyImage>
            <div>
              <div className="banner-post-header">
                <span className="banner-post-category">
                  {categoryPublicNameKR}
                </span>
                <p>{date}</p>
              </div>
              <h5>{title}</h5>
            </div>
          </Link>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .banner-post {
    display: grid;
    grid-template-columns: 75px 1fr;
    column-gap: 0.5rem;
    margin-bottom: 0.75rem;
    margin-left: 0.75rem;
    color: var(--clr-content);
  }
  .banner-post:hover {
    h5 {
      color: var(--clr-content-semiemphasis);
    }
  }
  .img {
    border-radius: var(--radius);
    height: 50px;
    width: 75px;
  }
  .banner-post-header {
    display: flex;
    justify-content: space-between;
  }
  .banner-post-category {
    font-weight: 500;
    color: var(--clr-content-semiemphasis);
    font-size: 0.7rem;
  }
  h5 {
    font-size: 0.7rem;
    letter-spacing: 0;
    line-height: 1.2;
    color: var(--clr-content);
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.1rem;
    font-size: 0.6rem;
    color: var(--clr-content-semiemphasis);
  }
`

export default Trend

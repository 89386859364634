import React from 'react'

const Mark = (props) => {
  return (
    <mark
      style={{
        background: 'var(--clr-eye-friendly)',
        borderRadius: 'var(--radius)',
        padding: '0 0.25rem'
      }}
    {...props}/>
  )
}

export default Mark

import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { BiSitemap, BiRss } from 'react-icons/bi'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const FeedLinks = ({ styleClass }) => {
  const { sitemapUrl, rssFeedUrl } = useSiteMetadata()
  return (
    <Wrapper className={styleClass}>
      <Link to={sitemapUrl}>
        <BiSitemap
          className="feed-icon sitemap-icon"
          style={{ color: 'var(--clr-white)' }}
        />
      </Link>
      <Link to={rssFeedUrl}>
        <BiRss
          className="feed-icon rss-icon"
          style={{ color: 'var(--clr-white)' }}
        />
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  margin: 0 auto;
  margin-top: 0.5rem;
  li {
    a {
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      transition: var(--transition);
      .feed-icon.sitemap-icon,
      .feed-icon.rss-icon {
        font-size: 1.5rem;
      }
    }
  }
`
export default FeedLinks

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Trend } from '../../components'

const query = graphql`
  fragment PostFieldsSummary on Mdx {
    fields {
      category
      categoryPublicNameKR
    }
    frontmatter {
      title
      date(formatString: "YYYY-MM-DD")
      slug
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 1200, placeholder: BLURRED)
        }
      }
    }
    id
  }

  query IndexPageQuery {
    allPosts: allMdx(
      limit: 1000
      sort: { frontmatter: { date: DESC } }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        ...PostFieldsSummary
      }
    }
    allPageViews: allTotalPageViews(limit: 5) {
      edges {
        node {
          id
          count
        }
      }
    }
    recent5Posts: allMdx(
      limit: 5
      sort: { frontmatter: { date: DESC } }
      filter: {
        fields: { division: { eq: "post" } }
        frontmatter: { published: { eq: true } }
      }
    ) {
      nodes {
        ...PostFieldsSummary
      }
    }
    recent5Projects: allMdx(
      limit: 5
      sort: { frontmatter: { date: DESC } }
      filter: {
        fields: { division: { eq: "project" } }
        frontmatter: { published: { eq: true } }
      }
    ) {
      nodes {
        ...PostFieldsSummary
      }
    }
  }
`

const BannerPosts = () => {
  const {
    allPosts: { nodes: allPosts },
    allPageViews: { edges: popularPosts },
    recent5Posts: { nodes: recent5Posts },
    recent5Projects: { nodes: recent5Projects }
  } = useStaticQuery(query)

  function chooseTop5(allPosts, popularPosts) {
    const results = []
    for (const a of popularPosts) {
      const selectedPost = allPosts.find(
        b => `/blog/${b.frontmatter.slug}/` === a.node.id.split('@')[1]
      )
      if (selectedPost === null) {
        continue
      } else {
        results.push({
          count: a.node.count,
          ...selectedPost,
        })
      }
      if (results.length >= 5) {
        break
      }
    }
    return results
  }

  const top5Posts = chooseTop5(allPosts, popularPosts)

  return (
    <>
      <Trend posts={recent5Posts} title="최근글" />
      <Trend posts={top5Posts} title="인기글" />
      <Trend posts={recent5Projects} title="최근프로젝트" />
    </>
  )
}

export default BannerPosts

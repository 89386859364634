import React from 'react'
import styled from 'styled-components'
import About from './About'
import BannerCategories from './BannerCategories'
import BannerPosts from './BannerPosts'
import BannerTags from './BannerTags'

const Banner = () => {
  return (
    <Wrapper>
      <About introduction="웹 개발, 데이터 분석" />
      <BannerCategories />
      <BannerTags title="태그" />
      <BannerPosts />
    </Wrapper>
  )
}

const Wrapper = styled.aside`
  display: grid;
  grid-template-columns: 300px;
  justify-content: center;
  row-gap: 1rem;
  height: min-content;
  @media (min-width: 576px) {
    & {
      grid-template-columns: repeat(auto-fit, 300px);
      grid-auto-rows: min-content;
      column-gap: 1rem;
    }
  }
`
  
export default Banner

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Pagination = ({
  division,
  isFirst,
  isLast,
  prevPage,
  nextPage,
  numPages,
}) => {
  return (
    <Wrapper>
      {!isFirst && (
        <Link to={prevPage} rel="prev">
          ←
        </Link>
      )}
      {Array.from({ length: numPages }, (_, i) => (
        <Link
          key={`${division}-page-${i + 1}`}
          to={`/blog/${division}s${i === 0 ? '' : `/page/${i + 1}`}/`}
        >
          {i + 1}
        </Link>
      ))}
      {!isLast && (
        <Link to={`${nextPage}`} rel="next">
        →
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  display: flex;
  justify-content: start;
  align-items: center;
  list-style: none;
  margin: 6rem auto 0 auto;
  padding: 0;
  max-width: var(--max-width);
  position: absolute;
  top: 1.5rem;
  right: 30px;
  font-size: 1rem;
  a {
    color: var(--clr-hyperlink);
    text-align: center;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: var(--radius);
  }
  a.active {
    background-color: var(--clr-base);
    color: var(--clr-white);
  }
  a:hover:not(.active) {
    background-color: var(--clr-hover-border-background-dark);
  }
`

export default Pagination

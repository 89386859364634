import React from 'react'
import styled from 'styled-components'

const TwoColumns = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.75rem;
  }
`

export default TwoColumns

import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  {
    postCategories: allMdx(
      filter: {
        fields: { division: { eq: "post" } }
        frontmatter: { published: { eq: true } }
      }
    ) {
      group(field: { fields: { category: SELECT } }) {
        fieldValue
        distinct(field: { fields: { categoryPublicNameKR: SELECT } })
        totalCount
        subcategories: group(field: { fields: { subcategory: SELECT } }) {
          fieldValue
          totalCount
          distinct(field: { fields: { subcategoryPublicNameKR: SELECT } })
        }
      }
    }
    projectCategories: allMdx(
      filter: {
        fields: { division: { eq: "project" } }
        frontmatter: { published: { eq: true } }
      }
    ) {
      group(field: { fields: { category: SELECT } }) {
        fieldValue
        distinct(field: { fields: { categoryPublicNameKR: SELECT } })
        totalCount
        subcategories: group(field: { fields: { subcategory: SELECT } }) {
          fieldValue
          totalCount
          distinct(field: { fields: { subcategoryPublicNameKR: SELECT } })
        }
      }
    }
  }
`

const Categories = ({ division, isSubcategoryClosed, isTotalCount }) => {
  const {
    postCategories: { group: postCategories },
    projectCategories: { group: projectCategories },
  } = useStaticQuery(query)

  const categories = division === 'post' ? postCategories : projectCategories

  function renderSubcategories(category, subcategories) {
    return (
      <ul>
        {subcategories.map(
          ({
            fieldValue: subcategory,
            distinct: subcategoryPublicNameKR, 
            totalCount,
          }) => {
            return (
              <li key={subcategory}>
                <Link
                  to={`/blog/category/${category}/${subcategory}/`}
                  key={subcategory}
                  className="subcategory-link"
                >
                  {subcategoryPublicNameKR}
                  <span>{totalCount}</span>
                </Link>
              </li>
            )
          },
        )}
      </ul>
    )
  }

  return (
    <ul>
      {categories.map(({ fieldValue: category, distinct: categoryPublicNameKR, totalCount, subcategories }) => {
        return (
          <li key={category}>
            <Link to={`/blog/category/${category}/`} className="category-link">
              {categoryPublicNameKR}
              {!isTotalCount && (
                <span className="category-counts">{totalCount}</span>
              )}
            </Link>
            {!isSubcategoryClosed &&
              renderSubcategories(category, subcategories)}
          </li>
        )
      })}
    </ul>
  )
}

export default Categories

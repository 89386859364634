// src/components/CaptionedImage.js
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const CaptionedImage = ({
  caption,
  src,
  photographer,
  photoUrl,
  platform,
  platformUrl,
}) => {
  const { titleAlt, siteUrl } = useSiteMetadata()
  const siteName = titleAlt

  return (
    <Wrapper>
      <figure>
        <GatsbyImage alt={caption} image={src} className="inline-img" />
        <figcaption className="fig-caption">
          <span className="caption">{caption}&nbsp;</span>
          <span className="source">
            <a href={photoUrl && photoUrl}>{photographer && photographer}</a>
            <span> - </span>
            <a href={platformUrl ? platformUrl : siteUrl}>
              {platform ? platform : siteName}
            </a>
          </span>
        </figcaption>
      </figure>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  figure {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
    a:link,
    a:visited {
      color: var(--clr-hyperlink);
      background-color: transparent;
      text-decoration: none;
    }
    a:hover {
      border-bottom: 1px solid var(--clr-hyperlink);
    }
    .fig-caption {
      margin: 0.375rem auto 0 auto;
      font-style: normal;
      font-size: 0.875rem;
      font-weight: 300;
      color: var(--clr-caption-text-color);
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      width: 90%;
      .source {
        margin-right: 0.25rem;
      }
    }
  }
`
export default CaptionedImage

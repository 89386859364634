import React, { useState } from 'react'
import { Footer, Navbar, Sidebar } from '../components'

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Adsense type="display-banner" /> */}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout

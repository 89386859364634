import React, { useState } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Title from './Title'
import SearchButtons from './SearchButtons'
import { FaEye, FaGithub } from 'react-icons/fa'
import styled from 'styled-components'

const IntroProjects = ({ projects: data = [], title, page }) => {
  const [projects, setProjects] = useState(data)

  const setBackToAll = () => {
    setProjects(data)
  }

  return (
    <Wrapper className="section">
      <Title title={title || 'projects'} />
      {/*serach buttons here */}
      {page && (
        <SearchButtons
          projects={data}
          setProjects={setProjects}
          setBackToAll={setBackToAll}
        />
      )}
      <div className="section-center">
        {projects.map(item => {
          const { id } = item
          const {
            title,
            slug,
            featuredImage,
            // projectUrl,
            githubRepositoryUrl,
          } = item.frontmatter

          const { category, subcategory } = item.fields

          return (
            <article key={id}>
              <div className="container">
                <GatsbyImage
                  image={getImage(featuredImage)}
                  alt={title}
                  className="img"
                />
                <div className="info">
                  <h3>{title}</h3>
                  <div className="classification-category">
                    <h4>{category}</h4>
                    <h4>{subcategory}</h4>
                  </div>
                  <span className="btn-container">
                    {/* <a href={projectUrl} className="link">
                      <BiLinkExternal />
                    </a> */}
                    <Link to={`/blog/${slug}/`} className="link">
                      <FaEye />
                    </Link>
                    <a href={githubRepositoryUrl} className="link" aria-label="Github">
                      <FaGithub />
                    </a>
                  </span>
                </div>
              </div>
            </article>
          )
        })}
      </div>
      {!page && (
        <Link to="/blog/projects/" className="btn">
          모든 프로젝트
        </Link>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--max-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  .section-center {
    margin-top: 3rem;
    max-width: var(--max-width);
    display: grid;
    gap: 2rem;
    /* safari workaround */
    grid-gap: 2rem;
    margin: 2rem auto 5rem auto;
    .img {
      height: 100%;
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article {
      box-shadow: var(--light-shadow);
      border-radius: var(--radius);
      transition: var(--transition);
    }
    article:hover {
      box-shadow: var(--dark-shadow);
    }
    .container {
      height: 25rem;
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      background: var(--clr-base);
      &:hover .img {
        opacity: 0.2;
      }
      .info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        opacity: 0;
        transition: var(--transition);
        color: var(--clr-white);
        text-align: center;
        margin: 1rem auto;
        .classification-category {
          display: grid;
          grid-template-columns: 1fr 1fr;
          place-items: center;
          text-transform: capitalize;
          margin: 0 6rem;
        }
        h3 {
          margin: 0.5rem;
          line-height: 1.25;
        }
      }
      &:hover .info {
        opacity: 1;
      }
      .btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      a {
        text-align: center;
        color: var(--clr-base-decoration);
        font-size: 3rem;
      }
      .link {
        display: flex;
        margin: 0.25rem 1rem;
      }
      a:hover {
        color: rgba(255, 255, 255, 0.3);
      }
    }
    @media (min-width: 992px) {
      .container {
        height: 20rem;
      }
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1200px) {
      .container {
        height: 15rem;
      }
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media screen and (min-width: 992px) {
    width: 92vw;
  }
`
export default IntroProjects

import React from 'react'
import styled from 'styled-components'
import { FeedLinks, SocialLinks } from '../constants'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const Footer = () => {
  const { title, email } = useSiteMetadata()

  // const { site } = useStaticQuery(query)
  // const {
  //   siteMetadata: { title, email },
  // } = site

  return (
    <Wrapper>
      <SocialLinks styleClass="footer-icons" />
      <p>
        &copy;{new Date().getFullYear()} {title}. All rights reserved.
      </p>
      <span>
        Email: {email}
      </span>
      <FeedLinks styleClass="footer-icons" />
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  height: 12.5rem;
  background: var(--clr-compliment);
  text-align: center;
  display: grid;
  place-items: center;
  position: sticky;
  top: 100vh;
  padding: 1rem;
  .footer-icons {
    display: flex;
    justify-content: center;
    line-height: 1;
  }
  .footer-icons a {
    font-size: 1.5rem;
    margin: 0 0.35rem;
    transition: var(--transition);
  }
  .footer-icons li {
    transition: var(--transition);
  }
  .footer-icons li:hover {
    transform: translateY(-10%);
  }
  p {
    margin-bottom: 0;
    color: var(--clr-white);
    text-transform: uppercase;
    letter-spacing: var(--spacing);
  }
  span {
    margin-bottom: 0;
    color: var(--clr-white);
    letter-spacing: var(--spacing);
  }
`

export default Footer
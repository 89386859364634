import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { CaptionedImage, DefinitionList, InternalLink } from './src/components'
import {
  Blockquote,
  Headings,
  InlineCode,
  InlineQuote,
  Mark,
  PrismSetup,
  Sup,
} from './src/components/Complete'

const components = {
  wrapper: ({ children }) => <section className="mdx-page">{children}</section>,
  // wrapper: ({ components, ...rest }) => <section className="mdx-page" {...rest} />,
  blockquote: Blockquote,
  code: InlineCode,
  h2: Headings.StyledH2,
  h3: Headings.StyledH3,
  h4: Headings.StyledH4,
  h5: Headings.StyledH5,
  pre: PrismSetup,
  q: InlineQuote,
  sup: Sup,
  theme: {
    blockquote: Blockquote,
    mark: Mark,
  },
  Link,
  GatsbyImage,
  CaptionedImage,
  DefinitionList,
  InternalLink,
}

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}

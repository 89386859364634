import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const InternalLink = ({ slug }) => {
  const data = useStaticQuery(graphql`
    query AllPostsQuery {
      allMdx {
        nodes {
          id
          fields {
            myMdxPath
          }
          frontmatter {
            title
            slug
          }
        }
      }
    }
  `)

  const post = data.allMdx.nodes.find(node => node.frontmatter.slug === slug)

  return (
    post && <Link to={post.fields.myMdxPath}>{post.frontmatter.title}</Link>
  )
}

export default InternalLink

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Facebook = ({
  name = null,
  url,
  locale,
  localeAlternate,
  type = 'website',
  title,
  description,
  image,
}) => (
  <Helmet>
    {name && <meta property="og:site_name" content={name} />}
    <meta property="og:locale" content={locale} />
    <meta property="og:locale:alternate" content={localeAlternate} />
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image} />
    <meta property="og:image:alt" content={description} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="627" />
  </Helmet>
)

export default Facebook

Facebook.propTypes = {
  url: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
}